@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
  }

  &:checked + label .box {
    display: none;
  }
  &:checked + label .checked {
    background-color: #000;
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .notChecked,
  &:hover + label .required,
  &:focus + label .notChecked,
  &:focus + label .required,
  &:checked + label .required {
    stroke: var(--colorBlack);
  }

  &:checked + label .notChecked {
    stroke: none;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--colorBlack);
  }

  /* Disabled radio-button label should look like disabled */
  &:disabled + label {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:disabled + label .text {
    cursor: not-allowed;
  }
}

.blue {
  & .input {
    &:checked + label .checked {
      & rect {
        fill: #58748a;
      }
    }
  }
  & path {
    stroke: #58748a;
  }
  & svg {
    border-radius: 2px;
  }
}

.label {
  display: flex;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 5px;

  @media (--viewportMedium) {
    padding-top: 0px;
    padding-bottom: 8px;
  }
  & span {
    color: var(--marketplaceTextColor);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.305px;
  }
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 24px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: center;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkedStyle {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.checked {
  display: none;
}

.notChecked {
  stroke: var(--colorGrey300);
  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: var(--colorAttention);
  &:hover {
    stroke: pink;
  }
}

.text {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--colorSuccess);
  }

  /* disabled version */
  &:hover + label .boxDisabled,
  &:focus + label .boxDisabled,
  &:checked + label .boxDisabled {
    stroke: var(--colorGrey300);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--colorSuccess);
    stroke-width: 1px;
  }

  /* Display the disabled when checked */
  &:checked + label .checkedDisabled {
    display: inline;
    stroke: var(--colorGrey300);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--colorBlack);
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}

.checkboxWrapper {
  height: 32px;
  margin-top: -2px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 24px;
    margin-top: -1px;
  }
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--colorSuccess);
}

.checkedDisabled {
  display: none;
  fill: var(--colorGrey300);
}

.boxDisabled,
.boxSuccess,
.box {
  stroke: var(--colorGrey300);
}

.text {
}
.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  padding: 0;
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
