@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px 1px #f0eaea;

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  height: 284px;
  @media (max-width: 767px) {
    height: 162px;
  }
  & > div {
    height: 100%;
  }
  & img {
    height: 284px;
    @media (max-width: 767px) {
      height: 162px;
    }
    /* object-fit: cover; */
  }
}

.responsiveImg {
  height: 284px;
  @media (max-width: 767px) {
    height: 162px;
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 7px 0 2px 10px;
  @media (max-width: 767px) {
    padding: 3px 0 5px 10px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: left;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
  }
}

.perUnit {
  /* Font */
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 1px;
  text-align: left;
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;

  margin: 0;
  @media (max-width: 767px) {
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
  }
}

.branName {
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  text-transform: uppercase;

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 767px) {
    padding: 2px 0;
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
  }
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
