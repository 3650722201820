@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--colorGrey100);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--colorGrey700);
}

.noImageText {
  composes: h6 from global;
  color: var(--colorGrey700);
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: var(--borderRadius);
}
.removeImage {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 33px;
  height: 33px;
  padding: 0;

  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;

  /* & svg {
 
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--colorGrey300);
    stroke: var(--colorGrey300);
    text-align: right;
    justify-content: end;
  }

  &:hover svg {
    fill: var(--colorWhite);
    stroke: var(--colorWhite);
  } */
}
.rootImgURL {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: white;
}

.rootImgURLwithAlgin{
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: white;
  text-align: center;
}