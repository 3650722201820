.customField {
  margin-bottom: 24px;

  &:last-child {
    /* margin-bottom: 0; */
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      /* margin-bottom: 0; */
    }
  }
}
