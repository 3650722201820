.root {
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}

.iconHover {
  & .tooltipWrapper {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  & .title {
    font-size: 16px;
    font-weight: 400;
    line-height: 23.76px;
    text-align: left;
    display: block;
    margin-bottom: 12px;
  }

  & .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    text-align: left;
  }
  /* Tooltip content hidden by default */
  & .toolTipTextWrapper,
  & .arrow {
    visibility: hidden;
    opacity: 0;
    /* transition: all .1s ease-in-out;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    pointer-events: none; */
  }

  /* Tooltip position */
  & .toolTipTextWrapper {
    position: absolute;
    bottom: 78%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -170px;
    padding: 16px 14px 16px 20px;
    width: 338px;
    border-radius: var(--marketplaceButtonBorderRadius);
    max-width: 374px;
    border-radius: 8px;
    background: #f5edee;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* content: attr(data-tooltip); */
    text-align: left;
    font-size: 12px;
    line-height: 21px;
    z-index: 2;
    @media (max-width: 576px) {
      left: 0%;
      width: 330px;
    }
    & .toolTipText {
      max-height: 200px;
      overflow-y: auto;
      display: block;
      padding-right: 15px;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 100px;
        background: var(--Cream, #f8f4f3);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      }

      &::-webkit-scrollbar-thumb {
        background: #e17b8c;
        border-radius: 100px;
      }
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      & h5 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 14px;
      }
      & h6 {
        font-size: 13px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 14px;
      }
      & p,
      & li {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      & b {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  /* Triangle hack for Tooltip bubble */
  & .arrow {
    position: absolute;
    bottom: 78%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #ddd;
    border-top: 5px solid hsla(0, 2%, 60%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
    font-size: 0;
    line-height: 0;
    z-index: 2;
  }

  /* Tooltip visible on hover */
  & .tooltipWrapper {
    &:hover {
      & .arrow,
      & .toolTipTextWrapper {
        /* transition: all .3s ease-in-out; */
        visibility: visible;
        /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100); */
        opacity: 1;
      }
    }
  }
}

.Bio {
  @media (max-width: 767px) {
    margin-left: 0 !important;
    width: 290px !important;
  }
}

.bundleShiping {
  @media (max-width: 767px) {
    margin-left: 0 !important;
    width: 290px !important;
  }
}
