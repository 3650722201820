@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;

  position: relative;
  @media (max-width:1023px) {
    overflow-x: auto;
  }
}

.darkSkin {
  /* background-color: var(--colorGrey700); */
  background-color: #253e4f;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.tabContent {
  composes: a from global;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;

  color: var(--colorGrey700);
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportMedium) {
    border-bottom-width: 3px;
    padding: 16px 16px 6px 16px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  border-bottom-color: var(--colorBlack);
  color: var(--colorBlack);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--colorGrey300);
  letter-spacing: -0.3047058880329132px;
  &:hover {
    color: var(--colorWhite);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--colorWhite);
  color: var(--colorWhite);
  position: relative;
  &:after{
    position: absolute;
    content:"" ;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0.928932L15.0711 8L8 15.0711L0.928932 8L8 0.928932Z' fill='%23253E4F'/%3E%3C/svg%3E%0A");
  width: 15px;
  height: 15px;
  right: 30%;
  top: -8px;
  z-index: 10;
  }
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}
